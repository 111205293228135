<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>

      <b-col sm="3" md="3" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="4" class="my-1">
        <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <!-- <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append> -->
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="3" md="5" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
    >
      <template #cell(name)="row">
        <img class="imgi" src="../assets/images/logo/rugfreecoins.png" alt="">
          {{ row.value.first }} {{ row.value.last }}
        
      </template>
      
      <template #cell(votes)="row">
        <b-button class="btn-secondary">
          {{row.value}}
        </b-button>
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" class="mr-1">
          Info modal
        </b-button>
        <b-button size="sm" @click="row.toggleDetails">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </b-container>
</template>

<script>

import {
  BContainer,
  BModal,
  BTable,
  BCard,
  BButton,
  BRow,
  BPagination,
  BCol,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  // BInputGroupAppend,
} from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BModal,
    BTable,
    BCard,
    BButton,
    BRow,
    BPagination,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
  },
    data() {
      return {
        items: [
          { index: 1, age: 40, name: { first: 'Dickerson' },holders:5000, market:8457222, price:0.0004,votes:564841321, },
          {  index: 2, age: 21, name: { last: 'Shaw' },holders:35000, market:8457222, price:0.0004,votes:564841321, },
          {  index: 3, age: 9, name: {  last: 'Navarro' },holders:3435000, market:8457222, price:0.0004,votes:564841321, },
          {  index: 4, age: 89, name: { first: 'Geneva' },holders:5345000, market:8457222, price:0.0004,votes:564841321, },
          { index: 5, age: 38, name: { last: 'Carney' },holders:535000, market:8457222, price:0.0004,votes:564841321, },
          { index: 6, age: 27, name: { last: 'Dunlap' },holders:55000, market:8457222, price:0.0004,votes:564841321, },
          { index: 7, age: 40, name: { last: 'Macdonald' },holders:55000, market:8457222, price:0.0004,votes:564841321, },
          { index: 8, age: 87, name: { last: 'Shaw' },holders:503400, market:8457222, price:0.0004,votes:564841321,},
          { index: 9, age: 26, name: { first: 'Mitzi' },holders:5054500, market:8457222, price:0.0004,votes:564841321, },
          { index: 10, age: 22, name: { first: 'Genevieve' },holders:4535000, market:8457222, price:0.0004,votes:564841321, },
          { index: 11, age: 38, name: { last: 'Carney' },holders:5004530, market:8457222, price:0.0004,votes:564841321, },
          {  index: 3, age: 9, name: {  last: 'Navarro' },holders:504500, market:8457222, price:0.0004,votes:564841321, },
          {  index: 4, age: 89, name: { first: 'Geneva' },holders:5035400, market:8457222, price:0.0004,votes:564841321, },
          { index: 5, age: 38, name: { last: 'Carney' },holders:435000, market:8457222, price:0.0004,votes:564841321, },
          { index: 6, age: 27, name: { last: 'Dunlap' },holders:2235000, market:8457222, price:0.0004,votes:564841321, },
          { index: 7, age: 40, name: { last: 'Macdonald' },holders:235000, market:8457222, price:0.0004,votes:564841321, },
          { index: 8, age: 87, name: { last: 'Shaw' },holders:245000, market:8457222, price:0.0004,votes:564841321,},
          { index: 9, age: 26, name: { first: 'Mitzi' },holders:235000, market:8457222, price:0.0004,votes:564841321, },
          { index: 10, age: 22, name: { first: 'Genevieve' },holders:245000, market:8457222, price:0.0004,votes:564841321, },
          { index: 11, age: 38, name: { last: 'Carney' },holders:245000, market:8457222, price:0.0004,votes:564841321, },
          {  index: 3, age: 9, name: {  last: 'Navarro' },holders:245000, market:8457222, price:0.0004,votes:564841321, },
          {  index: 4, age: 89, name: { first: 'Geneva' },holders:245000, market:8457222, price:0.0004,votes:564841321, },
          { index: 5, age: 38, name: { last: 'Carney' },holders:55435000, market:8457222, price:0.0004,votes:564841321, },
          { index: 6, age: 27, name: { last: 'Dunlap' },holders:6545000, market:8457222, price:0.0004,votes:564841321, },
          { index: 7, age: 40, name: { last: 'Macdonald' },holders:4565000, market:8457222, price:0.0004,votes:564841321, },
          { index: 8, age: 87, name: { last: 'Shaw' },holders:5465000, market:8457222, price:0.0004,votes:564841321,},
          { index: 9, age: 26, name: { first: 'Mitzi' },holders:4565000, market:8457222, price:0.0004,votes:564841321, },
          { index: 10, age: 22, name: { first: 'Genevieve' },holders:4565000, market:8457222, price:0.0004,votes:564841321, },
          { index: 11, age: 38, name: { last: 'Carney' },holders:545000, market:8457222, price:0.0004,votes:564841321, },
          { index: 12, age: 29, name: { first: 'Dick' },holders:6455000, market:8457222, price:0.0004,votes:564841321, }
        ],
        fields: [
          { key: 'index', label: 'Rank', sortable: true, class: 'text-center' },
          { key: 'name', label: 'Coin', sortable: true, sortDirection: 'desc' },
          { key: 'holders', label: 'Total Holders', sortable: true, class: 'text-center' },
          { key: 'market', label: 'Market Cap', sortable: true, class: 'text-center' },
          { key: 'price', label: 'Price', sortable: true, class: 'text-center' },
          { key: 'age', label: 'Age', sortable: true, class: 'text-center' },
          { key: 'votes',label: 'Votes'}
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [ 10, 15, 20, 25, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''
        }
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>

<style scoped>
.container-fluid, [dir=ltr] .container-xxl, [dir=ltr] .container-xl, [dir=ltr] .container-lg, [dir=ltr] .container-md, [dir=ltr] .container-sm {
    padding-right: 1rem;
    padding-left: 1rem;
    /* margin-right: auto;
    margin-left: auto; */
    padding-top: 10px;
}

.input-group:not(.has-validation) > .form-control:not(:last-child), [dir=ltr] .input-group:not(.has-validation) > .custom-select:not(:last-child), [dir=ltr] .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 20px;
}
.imgi{
  width: 30px;
  height: 30px;
}
.btn-secondary {
    color: #054fa8;
    border-radius: 30px;
    border-color: #08cbef ;
    background-color: #010c0e ;
}
.dark-layout .table tbody tr:not([class*=table-]) td {
    color: #fefeff;
}
/* .input-group > .input-group-append > .btn, [dir=ltr] .input-group > .input-group-append > 
.input-group-text, [dir=ltr] .input-group > .input-group-prepend:not(:first-child) > 
.btn, [dir=ltr] .input-group > .input-group-prepend:not(:first-child) > .input-group-text, [dir=ltr] 
.input-group > .input-group-prepend:first-child > .btn:not(:first-child), [dir=ltr] .input-group > 
.input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-radius: 20px;
} */
</style>

