<template>
  <div>
    <!-- <b-row v-if="api_loading" class="match-height">
      <b-col lg="12">
        <div class="d-flex justify-content-center mb-3 col-12 text-center">
          <b-spinner variant="primary" label="Loading..."></b-spinner>
        </div>
      </b-col>
    </b-row> -->
    <!-- <socials v-if="is_mobilesize"/> -->
    <!-- <socials2 v-if="!is_mobilesize" :class="is_mobilesize ? 'pr-5': ''"  /> -->
    <!-- <head-banner :class="is_mobilesize ? 'pt-4':''" /> -->
    <!-- <add-here /> -->
    <!-- <swap-carousel /> -->
    <!-- <Popup /> -->
    <!-- <blink-add :cryptoDataTrusted="cryptoDataPromoted" /> -->

    <b-row class="match-height promoted-secondary mb-1 text-center">
      <b-col lg="12">
        <b-row class="match-height mb-1 text-center">
          <b-col lg="12">
            <h1>Promoted coins 💎</h1>
            <h5>Only audited coins are listed in the paid promotions section.</h5>
          </b-col>
        </b-row>
        <b-row class="match-height mt-1">
          <b-col lg="12"
            ><div>
              <promoted-coin-table></promoted-coin-table>
            </div>
          </b-col>
        </b-row> 
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center mb-3 col-12 text-center">
      <iframe
        data-aa="1717027"
        src="//ad.a-ads.com/1717027?size=970x90"
        :class="!is_mobilesize ? 'iframe-desktop' : 'iframe-mobile'"
      ></iframe>
    </div>
    <b-row v-if="loading" class="match-height">
      <b-col lg="12">
        <div class="d-flex justify-content-center mb-3 col-12 text-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-col>
    </b-row>

    <!-- This tabs content will always be mounted -->

    <b-tabs content-class="mt-1">
      <b-tab class="tbl-secondary" :title="!is_mobilesize ? 'All time best' : 'ALL TIME'">
        <b-row class="match-height mb-1 text-center">
          <b-col lg="12">
            <h1>Top Coins of All Time</h1>
            <h5>Find the highest voted coins of all time</h5>
          </b-col>
        </b-row>
        <b-row class="match-height mt-1">
          <b-col lg="12"
            ><div>
              <coin-table></coin-table>
            </div>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab class="tbl-secondary" :title="!is_mobilesize ? 'Today best' : 'TODAY'" lazy>
        <b-row class="match-height mb-1 text-center">
          <b-col lg="12">
            <h1>Top Coins Today</h1>
            <h5>Find the highest voted coins in the past 24 hours</h5>
          </b-col>
        </b-row>
        <b-row class="match-height mt-1">
          <b-col lg="12"
            ><div>
              <coin-table></coin-table>
            </div>
          </b-col>
        </b-row>

        <b-row class="match-height mt-1"> </b-row>
      </b-tab>
      <b-tab class="tbl-secondary" :title="!is_mobilesize ? 'Premium' : 'AUDITED'" lazy>
        <b-row class="match-height mb-1 text-center">
          <b-col lg="12">
            <h1>Premium Coins</h1>
            <h5>Premium coins with whale tracking</h5>
          </b-col>
        </b-row>
        <b-row class="match-height mt-1">
          <b-col lg="12"
            ><div>
              <coin-table></coin-table>
            </div>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner, BTabs, BTab } from "bootstrap-vue";
import CoinTable from "../views/CoinTable.vue";
import PromotedCoinTable from "../views/PromotedCoinTable.vue";
// import CryptoTable from "../views/crypto/CryptoTable";
import { mixinList } from "@/mixins/mixinList";
// import SwapCarousel from "@/@core/components/carousel/SwapCarousel.vue";
// import AddHere from "@/@core/components/AddHere.vue";
// import Popup from "@/@core/components/carousel/Popup.vue";
// import HeadBanner from "@/@core/components/carousel/HeadBanner.vue";
// import AddHere from "@/@core/components/AddHere.vue";
// import BlinkAdd from './BlinkAdd.vue'

export default {
  mixins: [mixinList],
  components: {
    BRow,
    // BTable,
    BCol,
    BSpinner,
    CoinTable,
    PromotedCoinTable,
    // CryptoTable,
    BTabs,
    BTab,
    // BFormInput,
    // SwapCarousel,
    // HeadBanner,
    // Popup,
    // BLink,
    // BImg,
    // BlinkAdd
    // AddHere
  },
  data() {
    return {
      loading: false,
      is_show_marketing: true,
    };
  },
};
</script>

<style>
.nav-tabs .nav-link {
    border: none;
    padding: 0.61rem 1.2rem;
    border-radius: 0;
    border-radius: 30px;
    background-color: black;
}
.container-sm {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
}
.b-table {
  color: rgb(163, 33, 33);
}
.iframe-desktop {
  width: 970px;
  height: 90px;
  border: 3px black;
  padding: 0;
  overflow: hidden;
  background-color: black;
}
.iframe-mobile {
  width: 350px;
  height: 90px;
  border: 0px;
  padding: 0;
  overflow: hidden;
  background-color: black;
}
.promoted-secondary {
    border-radius: 30px;
    background-color: black;
    margin: 10px;
    padding-top: 20px;
    padding-bottom: 15PX;
}
.tbl-secondary {
    border-radius: 30px;
    background-color: black;
    margin: 10px;
    padding-top: 20px;
    padding-bottom: 15PX;
}
.icon-class {
  margin-left: 20px;
  margin-right: -15px;
}
.close-ion {
  margin-bottom: 20px;
}
.fixed_button {
  position: fixed;
  bottom: -1px;
}
.fixed_button2 {
  position: fixed;
  bottom: 15px;
  cursor: pointer;
}
.chat-us-button {
  background-color: #3664e5 !important;
  border-color: #3664e5 !important;
  border-radius: 25px;
  font-size: 20px;
  color: azure;
  padding-inline: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.login-button {
  position: fixed;
  height: 100px;
}
@media (max-width: 1024px) {
  img {
    border-style: none;
    border-radius: 10px;
  }
  .close-ion {
    margin-left: 120%;
    margin-bottom: 5px;
  }
}
img {
  border-style: none;
  /* border-radius: 10px; */
}
.card {
  border: none;
  margin-bottom: 2rem;
  border-radius: 0px;
}

@media (max-width: 1024px) {
  .img-fluid {
    max-width: 100% !important;
  }
  .nav-tabs .nav-link {
    padding: 0.2rem 0.4rem !important;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #070000;
    margin-inline: 2px;
    border-radius: 10px;
    /* padding: 2px; */
  }
  .alert-text {
    font-size: 8px;
  }
}
@media (min-width: 1024px) {
  .nav-tabs .nav-link {
    font-weight: 600;
    font-size: large;
  }
}
</style>

<style lang="scss" scoped>
.translucent-wrap {
  height: 110px;
  position: relative;
}
@font-face {
  font-family: "icomoon";
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?3qkin2");
  src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.eot?#iefix3qkin2")
      format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.woff?3qkin2")
      format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.ttf?3qkin2")
      format("truetype"),
    url("fhttps://s3-us-west-2.amazonaws.com/s.cdpn.io/93/icomoon.svg?3qkin2#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.social-networks {
  margin: 0 0 35px;
  padding: 0;
  font-size: 0;
  text-align: center;

  li {
    display: inline-block;
  }

  a {
    display: block;
    position: relative;
    margin: 0 7px;
    width: 68px;
    height: 68px;
    font-size: 0;
    line-height: 0;
    text-align: center;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      margin-top: -50%;
      margin-left: -50%;
      border-radius: 100px;
      background-color: #00b0ed;
      transition: all 400ms;
    }

    &:after {
      position: relative;
      display: block;
      font-family: "icomoon";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #fff;
      font-size: 26px;
      line-height: 66px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.spin-icon a:hover:after,
  &.spin-icon a:focus:after {
    transition: transform 0.4s ease-out;
    transform: rotateZ(360deg);
  }

  &.square a:hover:before,
  &.square a:focus:before {
    border-radius: 0;
  }

  &.bounce a:hover,
  &.bounce a:focus {
    animation: bounce 1s;
  }

  $networks: linkedin "\eac9"#0077b5, facebook "\e603"#3b5998,
    twitter "\e618"#00b0ed, github "\eab1"#4183c4, instagram "\e604"#3f729b,
    vimeo "\ea9c"#1ab7ea, twitch "\ea9b"#6441a5, pinterest "\e605"#cc2127;

  @each $network in $networks {
    .icon-#{nth($network, 1)} {
      &:after {
        content: "#{nth($network, 2)}";
      }

      &:hover:before,
      &:focus:before {
        background: nth($network, 3);
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -6px, 0);
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

/* Extra stuff just to make the demo pretty. */
body {
  padding: 35px 0;
  color: #fff;
  font-family: "Lora", serif;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  background: #4183c4
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/93/noise-bg.png) repeat 0 0;
}

h1 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0px;
  font-size: 35px;
  line-height: 1;
}

h2 {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 45px;
  font-size: 12px;
  text-transform: uppercase;
}
h5 {
  font-family: "Montserrat", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

footer {
  margin: 75px 0 35px;
}

a {
  color: #4183c4;
  text-decoration: none;

  &:hover {
    color: lighten(#4183c4, 15%);
  }
}
.dark-layout .table tbody tr:not([class*=table-]) td {
    color: #fefeff;
}

</style>